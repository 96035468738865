<template>
<div>
    <h3>证书/资质</h3>
    <div class="cert-list">
        <div v-for="item in certs" :key="item.name">
            <img :src="item.src" :alt="item.name">
            <p>{{item.name}}</p>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            certs: [{
                    name: "出版物经营许可证",
                    src: require("../assets/cert01.jpg")
                },
                {
                    name: "营业执照",
                    src: require("../assets/cert02.jpg"),
                }
            ]
        }
    }
}
</script>

<style scoped>
h3{
    margin:6px 12px;
    border-bottom: 2px solid #333
}
.cert-list{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    text-align: center
}
.cert-list>div{
    width:31%;
    margin: 20px 0;
    padding: 6px 0;
    box-shadow: 0 0 3px #ccc
}
.cert-list>div img{
    width:97%
}
.cert-list>div p{
    display:inline-block;
    padding: 6px 12px;
    background-color: #ededed;
    border-radius: 5px
}
</style>
